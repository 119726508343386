import initSliders from '/lib/sliders'
import initHeadroom from '/lib/headroom'
import initMobileNav, { initNewNav } from '/lib/mobile-nav'
import initA11yFunctions from '/lib/accessibility'
import initCopyWebsite from '/lib/copy-website'
import initShareButtons from '/lib/share-buttons'
import initHotSpots from './lib/hotspots'
import initSplitTesting from './lib/split-testing'
import initTypeformFunctions from './lib/typeform'
import initSegmentTracking from './lib/segment/'
import initCalendlyButton from './lib/calendly-button'
import initFirstTimeUser from './lib/firsttimeuser'
// Old Site Temporary
import '/lib/child-theme'

initSegmentTracking()
initSliders()
initHeadroom()

if (document.querySelector('.nav-redux')) {
  initNewNav()
} else {
  initMobileNav()
}

initA11yFunctions()
initCopyWebsite()
initShareButtons()
initHotSpots()
initSplitTesting()
initTypeformFunctions()
initCalendlyButton()
initFirstTimeUser()
