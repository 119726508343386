import $ from 'jquery'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import SwipeListener from 'swipe-listener'
import { getCookie, setCookie } from '/lib/cookie'

const $doc = $(document)
const $mobileNav = $('.js-mobile-nav')
const $hamburger = $('.js-hamburger')
const $banner = $('.js-announcement-banner')
const $height = $banner.height() + 20

function pencilBannerIsActive() {
  const $announceActive = getCookie('announceBannerActive') === 'true'
  const $promoActive = getCookie('promoBannerActive') === 'true'

  if ($announceActive === true || $promoActive === true) {
    return true
  }

  return false
}

function hidePencilBanner() {
  if (pencilBannerIsActive() && window.innerWidth < 960) {
    $banner.slideUp()
    $('body').removeClass('banner-active')
    $('.main').css('margin-top', '0px')
  }
}

function showPencilBanner() {
  if (pencilBannerIsActive() && window.innerWidth < 960) {
    $('.main').css('margin-top', $height)
    $('body').addClass('banner-active')
    $banner.slideDown()
  }
}

function showMobileNav(event) {
  event.preventDefault()
  $mobileNav.toggleClass('is-active')
  $hamburger.toggleClass('is-active')
  if ($mobileNav.hasClass('is-active')) {
    hidePencilBanner()
    $hamburger.attr('aria-expanded', 'true')
    if (!navigator.userAgent.match(/(iPhone)/)) {
      disableBodyScroll($mobileNav)
    }
    $('.js-menu-1 li:first a').focus()
  } else {
    $hamburger.attr('aria-expanded', 'false')
    enableBodyScroll($mobileNav)
    showPencilBanner()
    $('.header__content--left .js-hamburger').focus()
  }
}

function hideSubNavActiveClasses() {
  $('.js-header-wrapper').removeClass('subnav-2-active')
  $('.js-menu-2').removeClass('active')
  $('body').removeClass('subnav-2-active')
}

function openMobileSubNav(event) {
  let el = $(this)
  event.preventDefault()
  $('.js-header-wrapper').addClass('subnav-2-active')
  el.next('.js-menu-2').addClass('active')
  $('body').addClass('subnav-2-active')
}

function closeMobileSubNav(event) {
  event.preventDefault()
  hideSubNavActiveClasses()
}

export default function initMobileNav() {
  $(window).on('load resize', function (event) {
    hideSubNavActiveClasses()
    if ($(window).width() < 960) {
      var container = document.body
      var listener = SwipeListener(container)
      container.addEventListener('swipe', function (e) {
        var directions = e.detail.directions
        if (directions.left) {
          if (
            !$('body').hasClass('subnav-2-active') &&
            $mobileNav.hasClass('is-active')
          ) {
            $mobileNav.removeClass('is-active')
            $hamburger.removeClass('is-active')
            $hamburger.attr('aria-expanded', 'false')
            showPencilBanner()
            enableBodyScroll($mobileNav)
            $('.header__content--left .js-hamburger').focus()
          }
          $('.js-header-wrapper').removeClass('subnav-2-active')
          $('.js-menu-2').removeClass('active')
          $('body').removeClass('subnav-2-active')
        }
      })
      $doc
        .off('click.nav')
        .on('click.nav', '.js-menu-1 > .has-sub-menu > a', openMobileSubNav)
      $doc.on('click', '.js-close-submenu', closeMobileSubNav)
    } else {
      $('.js-menu-1 > .has-sub-menu').on({
        mouseenter: function () {
          $('.js-mobile-nav').addClass('subnav-active')
        },
        mouseleave: function () {
          $('.js-mobile-nav').removeClass('subnav-active')
        },
      })
      $('.js-menu-2 > .has-sub-menu').on({
        mouseenter: function () {
          $('.js-mobile-nav').addClass('sub-subnav-active')
        },
        mouseleave: function () {
          $('.js-mobile-nav').removeClass('sub-subnav-active')
        },
      })
    }
  })
  $('.nav--hamburger').on('click', function (e) {
    if (e.target !== this) return
    $hamburger.attr('aria-expanded', 'false')
    $mobileNav.toggleClass('is-active')
    $hamburger.toggleClass('is-active')
    hideSubNavActiveClasses()
    showPencilBanner()
    enableBodyScroll($mobileNav)
  })
  $(window).on('resize', function (event) {
    if ($(window).width() > 960) {
      $doc.off('click.nav')
      $('.js-header-wrapper').removeClass('subnav-2-active')
      $('.js-menu-2').removeClass('active')
      $('body').removeClass('subnav-2-active')
    }
  })
  $doc.on('click', '.js-hamburger', showMobileNav)
  $doc.on('keydown', function (event) {
    if ($mobileNav.hasClass('is-active') && event.key == 'Escape') {
      $hamburger.attr('aria-expanded', 'false')
      $mobileNav.toggleClass('is-active')
      $hamburger.toggleClass('is-active')
      showPencilBanner()
      hideSubNavActiveClasses()
      $('.header__content--left .js-hamburger').focus()
      enableBodyScroll($mobileNav)
    }
  })
  $doc.on('focusin', function (event) {
    if ($(window).width() > 960) {
      var $target = $(event.target)
      if (!$target.closest('.js-mobile-nav').length) {
        if ($mobileNav.hasClass('is-active')) {
          $hamburger.attr('aria-expanded', 'false')
          $mobileNav.toggleClass('is-active')
          $hamburger.toggleClass('is-active')
          hideSubNavActiveClasses()
          enableBodyScroll($mobileNav)
        }
      }
    } else {
      var $target = $(event.target)
      if (!$target.closest('.js-header-wrapper').length) {
        if ($mobileNav.hasClass('is-active')) {
          $hamburger.attr('aria-expanded', 'false')
          $mobileNav.toggleClass('is-active')
          $hamburger.toggleClass('is-active')
          hideSubNavActiveClasses()
          enableBodyScroll($mobileNav)
        }
      }
    }
  })
}

// @todo: Break this out so it can be used in other places
// Mostly based on my Vue Pen: https://codepen.io/wildbeard/pen/JjWGMBx/b9e4b06aae55b7dedf28f58afac693e5
export function initNewNav() {
  /**
   * @param {Element} el
   */
  const show = (el) => {
    const width = getComputedStyle(el).width

    // Set the element to its natural height but without it being visible
    el.style.width = width
    el.style.display = getComputedStyle(el).display
    el.style.position = 'absolute'
    el.style.visibility = 'hidden'
    el.style.height = 'auto'

    /*
     * Unlike Vue we need to use getBoundingClientRect. I'm not entirely sure
     * why, but it seems since the element doesn't exist in Vue until state
     * change, style.height = 'auto' followed by getComputedStyle(el).height doesn't
     * result in height === 'auto' but the actual pixel value.
     * However, since the element does exist for us, setting the style to 'auto'
     * and using getComputedStyle(el).height results in height === 'auto' rather
     * than a pixel value.
     */
    const height = el.getBoundingClientRect().height

    // Hide it again
    el.style.display = null
    el.style.width = null
    el.style.position = null
    el.style.visibility = null
    el.style.height = null
    el.style.opacity = null

    // Re-compute element
    getComputedStyle(el).height

    // The grand reveal
    requestAnimationFrame(() => {
      el.style.height = `${height}px`
      el.style.opacity = 1
    })
  }

  /**
   * @param {Element} el
   */
  const hide = (el) => {
    const height = getComputedStyle(el).height
    el.style.height = height

    // Re-compute element
    getComputedStyle(el).height

    // The grand hide
    requestAnimationFrame(() => {
      el.style.height = null
      el.style.opacity = null
    })
  }

  /**
   * @param {Element} target
   */
  const hideSiblings = (target) => {
    const sibs = Array.from(target.parentNode.children).filter(
      (s) => s !== target
    )
    const openClass = 'nav-redux__item--sub-open'

    sibs.forEach((s) => {
      if (s.classList.contains(openClass)) {
        const sub = s.querySelector('.nav-redux__sub')
        hide(sub)
        sub.parentElement.classList.remove(openClass)
      }
    })
  }

  /**
   * @param {Event} event
   * @param {Element} parent
   */
  const toggleChildren = (_, parent) => {
    const el = parent.querySelector('.nav-redux__sub')
    const openClass = 'nav-redux__item--sub-open'

    // Toggle Siblings
    hideSiblings(parent)

    // Toggle Current
    // @todo: On desktop, when opening, add a scroll event to close when
    // the nav leaves the viewport
    parent.classList.contains(openClass) ? hide(el) : show(el)
    parent.classList.toggle(openClass)
  }

  /**
   * @param {Event} event
   */
  const toggleNav = (event) => {
    event.preventDefault()
    const target = document.querySelector('.nav-redux__wrapper.mobile-only')

    // Hide open sub-menus when closing
    if (target.classList.contains('nav-redux__wrapper--open')) {
      target
        .querySelectorAll('.nav-redux__item--has-children')
        .forEach((el) => {
          const sub = el.querySelector('.nav-redux__sub')
          hide(sub)
          el.classList.remove('nav-redux__item--sub-open')
        })
    }

    target.classList.toggle('nav-redux__wrapper--open')
  }

  /**
   * @param {Event} e
   */
  const handleHover = (e) => {
    const parent = e.currentTarget

    if (!parent || parent.classList.contains('nav-redux__item--sub-open')) {
      return
    }

    const target = parent.querySelector('.nav-redux__sub')
    parent.classList.toggle('nav-redux__item--sub-open')
    show(target)
  }

  /**
   * @param {Event} e
   * @todo: Figure out flickering when moving between sub-nav elements
   */
  const handleHoverLeave = (e) => {
    const parent = e.currentTarget
    const target = parent.querySelector('.nav-redux__sub')
    const targetIsChild = e.composedPath().includes(target)

    if (!parent || targetIsChild) {
      return
    }

    parent.classList.toggle('nav-redux__item--sub-open')
    hide(target)
  }

  if (matchMedia('(max-width: 1079px)').matches) {
    document
      .querySelectorAll(
        '.nav-redux__wrapper.mobile-only .nav-redux__item--has-children'
      )
      .forEach((el) => {
        el.addEventListener('click', (e) => toggleChildren(e, el))
      })

    document
      .querySelector('.header-redux__content .hamburger')
      .addEventListener('click', toggleNav)
    document
      .querySelector('.nav-redux__wrapper.mobile-only .hamburger')
      .addEventListener('click', toggleNav)
  } else {
    document
      .querySelectorAll(
        '.nav-redux__wrapper:not(.mobile-only) .nav-redux__item--has-children'
      )
      .forEach((el) => {
        el.addEventListener('mouseover', handleHover)
        el.addEventListener('mouseleave', handleHoverLeave)
      })
  }
}
